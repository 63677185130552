import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { motion } from "framer-motion"; // Import motion for animations
import styles from "./Facilities.module.css"; // Importing the CSS module for custom styling
import aihandPng from "../../asset/aihand.png";
import consultingPng from "../../asset/consulting.png";
import communicationPng from "../../asset/communication.png";
import reportPng from "../../asset/report.png";

// Import your PNG icons
import elearningIcon from '../../asset/consulting.png';
import supplyChainIcon from '../../asset/consulting.png';
import healthcareIcon from '../../asset/consulting.png';
import chatbotIcon from '../../asset/communication.png';
import summarizationIcon from '../../asset/report.png';
import marketTrendIcon from '../../asset/consulting.png';
import marketingIcon from '../../asset/consulting.png';
import aiImage from '../../asset/aihand.png'; // AI illustration image

const Facilities = () => {
    const features = [
        { name: 'E-learning Assistance', icon: elearningIcon },
        { name: 'Supply Chain Management', icon: supplyChainIcon },
        { name: 'Healthcare Appointment Scheduling', icon: healthcareIcon },
        { name: 'Customer Support Chatbots', icon: chatbotIcon },
        { name: 'Document Summarization', icon: summarizationIcon },
        { name: 'Market Trend Analysis', icon: marketTrendIcon },
        { name: 'Personalized Marketing Recommendations', icon: marketingIcon },
    ];

    return (
      <section>
        <Box className={styles.container}>
            <Typography variant="h3" className={styles.header}>
                AI MEBOT FACILITIES
            </Typography>
            
            <Grid container spacing={2}>
                {/* Left Section - Feature List */}
                <Grid item xs={12} md={6} className={styles.leftSection}>
                    <ul className={styles.featureList}>
                        {features.map((feature, index) => (
                            <li key={index} className={styles.featureItem}>
                                <img src={feature.icon} alt={`${feature.name} icon`} className={styles.icon} />
                                {feature.name}
                            </li>
                        ))}
                    </ul>
                </Grid>

                {/* Right Section - Image */}
                <Grid item xs={12} md={6} className={styles.rightSection}>
                    <img src={aiImage} alt="AI Illustration" className={styles.image} />
                </Grid>
                {/* Add the Contact for Demo button here */}
                {/* <Button variant="contained" className={styles.demoButton}>
                        Contact for Demo
                </Button> */}
            </Grid>
        </Box>
      </section>
    );
};

export default Facilities;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Button, Box, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion'; // Import motion
import styles from './Navbar.module.css';
import { Email } from '@mui/icons-material';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#fff', color: '#000', boxShadow: 'none' }} className={styles.navbar}>
        {!isMobile && (
          <Toolbar className={styles.topBar}>
            <Box sx={{ padding: '0px 70px' }} className={styles.infoContainer}>
              <Box className={styles.infoBox}>
                <AccessTimeIcon fontSize="small" className={styles.icon} />
                <Typography variant="body2" className={styles.text}>&nbsp;24/7</Typography>
              </Box>
              <Box className={styles.infoBox}>
                <PhoneIcon fontSize="small" className={styles.icon} />
                <Typography variant="body2" className={styles.text}>+91 70304 03299</Typography>
              </Box>
              <Box className={styles.infoBox}>
                <Email fontSize="small" className={styles.icon} />
                <Typography variant="body2" className={styles.text}>info@mebot.live</Typography>
              </Box>
            </Box>
            <Box sx={{ padding: '0px 80px' }} className={styles.socialIcons}>
              <IconButton color="inherit" size="small" href="https://X.com/" className={styles.iconBtn}><TwitterIcon /></IconButton>
              <IconButton color="inherit" size="small" href="https://Facebook.com/" className={styles.iconBtn}><FacebookIcon /></IconButton>
              <IconButton color="inherit" size="small" href="https://Instagram.com/" className={styles.iconBtn}><InstagramIcon /></IconButton>
              <IconButton color="inherit" size="small" href="https://in.linkedin.com/company/pure-soft-solutions" className={styles.iconBtn}><LinkedInIcon /></IconButton>
            </Box>
          </Toolbar>
        )}

        {/* Main navigation */}
        <Toolbar className={styles.mainNav}>
          <motion.div
            whileHover={{ scale: 1.1 }} // Scale on hover
            transition={{ duration: 0.2 }}
          >
            <Typography onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} variant="h6" className={styles.logo}>
              AI-MEBOT
            </Typography>
          </motion.div>

          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              className={styles.menuIcon}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ textTransform: 'none' }} className={styles.navLinks}>
              {['home', 'about', 'services', 'testimonials', 'contact'].map((section) => (
                <motion.div
                  key={section}
                  whileHover={{ scale: 1.1 }} // Scale on hover
                  transition={{ duration: 0.2 }}
                >
                  <Button onClick={() => scrollToSection(section)} className={styles.link}>{section.charAt(0).toUpperCase() + section.slice(1)}</Button>
                </motion.div>
              ))}
            </Box>
          )}

          {!isMobile && (
            <div className={styles.actionButtons}>
              <motion.div
                whileHover={{ scale: 1.1 }} // Scale on hover
                transition={{ duration: 0.2 }}
              >
                <Button onClick={() => scrollToSection('contextexception')} color="inherit" variant="contained" className={styles.exploreButton}>Explore Agents</Button>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.1 }} // Scale on hover
                transition={{ duration: 0.2 }}
              >
                <Button onClick={handleLoginClick} color="inherit" variant="contained" className={styles.signInButton}>Sign In</Button>
              </motion.div>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile navigation */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        className={styles.drawer}
        classes={{
          paper: styles.drawerPaper,
        }}
      >
        <Box className={styles.drawerContainer}>
          {['home', 'about', 'services', 'testimonials', 'contact'].map((section) => (
            <motion.div
              key={section}
              whileHover={{ scale: 1.1 }} // Scale on hover
              transition={{ duration: 0.2 }}
            >
              <Button onClick={() => scrollToSection(section)} className={styles.drawerLink}>{section.charAt(0).toUpperCase() + section.slice(1)}</Button>
            </motion.div>
          ))}
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Navbar.module.css */
.Nav_logo__iBtNq {
    font-weight: bold;
    margin-right: auto; /* Pushes the logo to the left */
}

.Nav_grow__iN95d {
    flex-grow: 1; /* Pushes elements to the right */
}

.Nav_toolbar__EeTIk {
    display: flex;
    align-items: center; /* Vertically centers the items in the navbar */
}
`, "",{"version":3,"sources":["webpack://./src/component/dashboard/Nav.module.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,iBAAiB;IACjB,kBAAkB,EAAE,gCAAgC;AACxD;;AAEA;IACI,YAAY,EAAE,iCAAiC;AACnD;;AAEA;IACI,aAAa;IACb,mBAAmB,EAAE,+CAA+C;AACxE","sourcesContent":["/* src/styles/Navbar.module.css */\r\n.logo {\r\n    font-weight: bold;\r\n    margin-right: auto; /* Pushes the logo to the left */\r\n}\r\n\r\n.grow {\r\n    flex-grow: 1; /* Pushes elements to the right */\r\n}\r\n\r\n.toolbar {\r\n    display: flex;\r\n    align-items: center; /* Vertically centers the items in the navbar */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Nav_logo__iBtNq`,
	"grow": `Nav_grow__iN95d`,
	"toolbar": `Nav_toolbar__EeTIk`
};
export default ___CSS_LOADER_EXPORT___;

// ContextException.jsx
import React from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import styles from "./ContextException.module.css";
import pdfUpload from "../../asset/pdfupload.png"; // Add other demo images similarly

const ContextException = () => {

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/create-bot');
  };

  return (
    <Box>
      <Box id="contextexception" className={styles.container}>
        <Typography variant="h4" className={styles.header}>
          Context Exception MeBot
        </Typography>
        <Typography variant="body1" className={styles.paragraph}>
          In healthcare, a chatbot helps with patient scheduling, provides
          medical info, and supports telemedicine. For the industrial sector, it
          offers maintenance assistance, real-time inventory updates, and safety
          guidance.
        </Typography>
        {/* <Button variant="contained" color="inherit" className={styles.button}>
          Contact for demo
        </Button> */}
      </Box>

      {/* New section for demos */}
      <Box className={styles.demoSectionContainer}>
        <Grid container spacing={1} justifyContent="center">
          {/* First Row: 3 Demos */}
          <Grid item xs={12} sm={4}>
            <img
              src={pdfUpload} // Replace with actual image path for PDF
              alt="Mebot or Instruct"
              className={styles.demoImage}
            />
            <Typography variant="h6" align="center" className={styles.demoTitle}>
              Mebot For Instruct
            </Typography>
            <Button onClick={handleLoginClick} variant="contained" color="inherit" className={styles.demoButton}>
              Try Demo
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <img
              src={pdfUpload} // Replace with actual image path for Website
              alt="Mebot for Website"
              className={styles.demoImage}
            />
            <Typography variant="h6" align="center" className={styles.demoTitle}>
              Mebot For Audio
            </Typography>
            <Button variant="contained" color="inherit" className={styles.demoButton}>
              Try Demo
            </Button>
          </Grid>

          <Grid item xs={12}  md={4}>
            <img
              src={pdfUpload} // Replace with actual image path for YouTube
              alt="Mebot for YouTube"
              className={styles.demoImage}
            />
            <Typography variant="h6" align="center" className={styles.demoTitle}>
              Mebot For Video
            </Typography>
            <Button variant="contained" color="inherit" className={styles.demoButton}>
              Try Demo
            </Button>
          </Grid>

          {/* Second Row: 2 Demos */}
          {/* <Grid item xs={12} md={6}>
            <img
              src={pdfUpload} // Replace with actual image path for Voice
              alt="Mebot for Voice"
              className={styles.demoImage}
            />
            <Typography variant="h6" align="center" className={styles.demoTitle}>
              Mebot For Voice
            </Typography>
            <Button variant="contained" color="inherit" className={styles.demoButton}>
              Try Demo
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <img
              src={pdfUpload} // Replace with actual image path for Audio
              alt="Mebot for Audio"
              className={styles.demoImage}
            />
            <Typography variant="h6" align="center" className={styles.demoTitle}>
              Mebot For Audio
            </Typography>
            <Button variant="contained" color="inherit" className={styles.demoButton}>
              Try Demo
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default ContextException;

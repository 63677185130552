import React from "react";
import { Container, Typography, Box, Avatar } from "@mui/material";
import styles from "./Testimonial.module.css";
import AR from "../../asset/AR.png";
import Quote from "../../asset/quote.png";

const Testimonial = () => {
  return (
    <Container id="testimonials"sx={{ mt: 8, textAlign: "center" }}>
      <Typography variant="h3" fontWeight="bold" className={styles.heading}>
        SOME OF OUR TESTIMONIALS
      </Typography>
      {/* Testimonial Content */}
      <Box className={styles.testimonialBox}>
        <Avatar alt="Ankita Roy" src={AR} className={styles.avatar} />
        <Box className={styles.quoteIconContainer}>
          <Box className={styles.quoteIcon}>
            <img src={Quote} alt="Quote Icon" className={styles.quoteImage} />
          </Box>
        </Box>
        {/* Testimonial Name and Description */}
        <Typography variant="h6" className={styles.name}>
          Robert Zec
        </Typography>
        <Typography className={styles.description}>
          Mebot has transformed the way we engage with our customers. Its intelligent automation and personalized responses have significantly improved our efficiency and user satisfaction. The seamless integration with our existing systems makes it an invaluable asset to our team!"
        </Typography>
      </Box>
    </Container>
  );
};

export default Testimonial;

import React, { useState, useRef, useCallback, useMemo } from "react";
import { Box, Button, Tab, Tabs, Typography, IconButton, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios"; // Import axios for making HTTP requests
import { Delete, PictureAsPdf, Image as ImageIcon, Description, InsertDriveFile } from "@mui/icons-material"; // Import Material UI icons
import styles from "./FileUpload.module.css";
import { useNavigate } from "react-router-dom";
import TypingEffect from "./TypingEffect";
import uploadIcon from "../../asset/upload.png"; // Assuming you already have an upload icon

const MAX_FILES = 5; // Max number of files
const MAX_FILE_SIZE_MB = 20; // Max file size in MB
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // Max file size in bytes

const FileUpload = ({ onCancel }) => {
  const navigate = useNavigate(); 
  const [tabValue, setTabValue] = useState(0);
  const [fileNames, setFileNames] = useState([]); // Store file names
  const [filesToUpload, setFilesToUpload] = useState([]); // Store files for upload
  const [dragging, setDragging] = useState(false); // Handle drag state
  const [errorMessage, setErrorMessage] = useState(""); // Store error messages
  const [loading, setLoading] = useState(false); // Loading state for file upload
  const fileInputRef = useRef(null);

  // Handle tab changes
  const handleChange = (event, newValue) => setTabValue(newValue);

  // Validate file before adding
  const validateFiles = (files) => {
    const totalFiles = fileNames.length + files.length;

    // Check if the total number of files exceeds the limit
    if (totalFiles > MAX_FILES) {
      setErrorMessage(`You can only upload up to ${MAX_FILES} files.`);
      return false;
    }

    // Check if any file exceeds the size limit
    for (let file of files) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(
          `File ${file.name} exceeds the size limit of ${MAX_FILE_SIZE_MB}MB.`
        );
        return false;
      }
    }

    return true;
  };

  // Append new file names to the list
  const handleFilesSelection = useCallback(
    (files) => {
      if (!validateFiles(files)) return; // If validation fails, stop

      const selectedFileNames = Array.from(files).map((file) => file.name);
      setFileNames((prevFileNames) => [...prevFileNames, ...selectedFileNames]);
      setFilesToUpload((prevFiles) => [...prevFiles, ...files]); // Save the files to be uploaded
      setErrorMessage(""); // Clear the error message if successful
    },
    [fileNames]
  );

  // Handle file input change
  const handleFileChange = (event) => handleFilesSelection(event.target.files);

  // Handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    handleFilesSelection(event.dataTransfer.files);
  };

  // Trigger file input click
  const handleBrowseClick = () => fileInputRef.current.click();

  // Reset file input and state
  const handleCancel = () => {
    setFileNames([]);
    setFilesToUpload([]); // Clear selected files
    fileInputRef.current.value = null;
    setErrorMessage(""); // Clear any error messages\
    onCancel(); // Call the onCancel prop to notify parent
  };

  // Handle file delete
  const handleDeleteFile = (fileNameToDelete) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((fileName) => fileName !== fileNameToDelete)
    );
    setFilesToUpload((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileNameToDelete)
    );
  };

  // Handle file upload
  const handleUpload = async () => {
    if (fileNames.length === 0) {
      setErrorMessage("Please select at least one file to upload.");
      return;
    }

    setLoading(true); // Show loading spinner
    setErrorMessage(""); // Clear previous error messages

    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("files", file); // Append each file to form data
    });

    try {
      const response = await axios.post( 
        process.env.REACT_APP_SERVER_API,
        formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert(response.data.message); // Show success message
      handleCancel(); // Reset after successful upload
      navigate("/mebot-chat");
    } catch (error) {
      // Error handling
      if (error.response) {
        // Server responded with a status code other than 2xx
        setErrorMessage(error.response.data.message || "An error occurred during the upload.");
      } else if (error.request) {
        // No response was received
        setErrorMessage("No response from the server. Please make sure the server is running.");
      } else {
        // Other errors
        setErrorMessage("Error: " + error.message);
      }
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Memoize file list rendering for performance
  const renderedFileList = useMemo(
    () =>
      fileNames.map((name, index) => (
        <motion.div
          key={`${name}-${index}`}
          className={styles.fileItemContainer}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          <Box className={styles.fileItemBox}>
            <Typography className={styles.fileItem}>{name}</Typography>
            {/* Delete icon/button */}
            <IconButton
              aria-label="delete"
              onClick={() => handleDeleteFile(name)}
              className={styles.deleteButton}
            >
              <Delete />
            </IconButton>
          </Box>
        </motion.div>
      )),
    [fileNames]
  );

  return (
    <div className={styles.innerContainer}>
      <Typography variant="h5" className={styles.heading}>
        CREATE MEBOT
      </Typography>
      <hr className={styles.line} />

      {/* Tabs */}
      <Box className={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          className={styles.tabs}
          variant="fullWidth"
          centered
        >
          <Tab label="Media" />
        </Tabs>
      </Box>

      {/* Conditional rendering based on selected tab */}
      <Box
        className={`${styles.dragDropBox} ${dragging ? styles.dragging : ""}`}
        onDragOver={(e) => {
          e.preventDefault();
          setDragging(true);
        }}
        onDragLeave={() => setDragging(false)}
        onDrop={handleFileDrop}
      >
        {tabValue === 0 ? (
          <>
            {/* PDF tab content */}
            <img
              src={uploadIcon}
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <Typography className={styles.uploadText}>
              Drag and drop your files here to start uploading.
            </Typography>
            <hr className={styles.dragDropLine} />

            {/* Hidden file input */}
            <input
              type="file"
              accept=".pdf"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="inherit"
              className={styles.browseButton}
              onClick={handleBrowseClick}
            >
              Browse Files
            </Button>

            {/* File type icons */}
            <Box className={styles.fileTypes}>
              <PictureAsPdf className={styles.fileTypeIcon} />
              <Description className={styles.fileTypeIcon} />
              <InsertDriveFile className={styles.fileTypeIcon} />
              <ImageIcon className={styles.fileTypeIcon} />
            </Box>

            {/* Error Message */}
            {errorMessage && (
              <Typography
                color="error"
                variant="body2"
                className={styles.errorMessage}
              >
                {errorMessage}
              </Typography>
            )}

            {/* Loading Indicator */}
            {loading && <CircularProgress className={styles.loadingIndicator} />}

            {/* File Limitations */}
            <Typography variant="caption" className={styles.fileLimitations}>
              Maximum: 5 PDFs, 20MB per file
            </Typography>
          </>
        ) : (
          <Typography variant="h6" className={styles.comingSoonText}>
            This feature is coming soon!
          </Typography>
        )}
      </Box>

      {/* Uploaded Files */}
      {fileNames.length > 0 && (
        <>
          <Typography className={styles.fileHeading}>Uploaded Files:</Typography>
          <Box className={styles.fileListContainer}>{renderedFileList}</Box>
        </>
      )}

      {/* Next Button */}
      <motion.div
        className={styles.buttonContainer}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Button
          variant="contained"
          color="inherit"
          onClick={handleUpload}
          className={styles.nextButton}
        >
          Next
        </Button>
      </motion.div>
    </div>
  );
};

export default FileUpload;
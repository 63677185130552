import React from 'react';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styles from './ContactUs.module.css'; // Custom CSS module

const Contact = () => {
  return (
    <Box id="contact" className={styles.container} sx={{ backgroundColor: '#E9FFFB', height: '100%' }}>
      <Grid container spacing={1}>
        {/* Left Section: Contact Details */}
        <Grid item xs={12} md={3.5} className={styles.leftSection}>
          <Box className={styles.contactDetails}>
            <Typography variant="h1" className={styles.contactTitle}>Contact Us</Typography><div className={styles.separator}></div>
            <Typography variant="body1" className={styles.info}>
            <PhoneIcon fontSize="small" className={styles.icon} /> +91 70304 03299
            </Typography>
            {/* <Typography variant="body1" className={styles.info}><LocationOnIcon fontSize="small" className={styles.icon} /> Sector 12 Spin Road, Moshi Pune</Typography> */}
            <Typography variant="body1" className={styles.info}><AccessTimeIcon fontSize="small" className={styles.icon} /> 24/7</Typography>
            {/* <Typography variant="body1" className={styles.info1}>09:00AM - 7:00PM</Typography> */}
          </Box>
        </Grid>

        {/* Right Section: Form */}
        <Grid item xs={12} md={8.5} className={styles.rightSection}>
          <Box className={styles.formContainer}>
            <Typography variant="h3" className={styles.formTitle}>Lets connect for demo</Typography>
            <TextField fullWidth label="Name" className={styles.inputField} />
            <TextField fullWidth label="Mobile" className={styles.inputField} />
            <TextField fullWidth label="Email" className={styles.inputField} />
            <TextField fullWidth label="Message" className={styles.inputField} multiline rows={4} />
            <Button variant="contained" className={styles.submitButton}>Submit</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;

import React from 'react';
import { Grid, Typography, Button, Container } from '@mui/material';
import styles from './Services.module.css'; // Module CSS import
import Planning from "../../asset/planning.png";
import Encryption from "../../asset/encryption.png"
import Encryption1 from "../../asset/encryption1.png"
import Encryption2 from "../../asset/encryption2.png"
import Encryption3 from "../../asset/encryption3.png"
import Certificate from "../../asset/certificate.png"

const services = [
  { title: 'AI-Based Audit Tools', img: Planning },
  { title: 'Web Mebot Remediation', img: Encryption },
  { title: 'App Mebot Monitoring', img: Encryption1 },
  { title: 'Responsibility Consulting', img: Encryption2 },
  { title: 'Multimedia Mebot', img: Encryption3 },
  { title: 'Mebot Cost Estimation', img: Certificate }
];

const Services = () => {
  return (
    <section>
    <Container id="services" maxWidth="lg" className={styles.servicesContainer}>
      <Grid container justifyContent="space-between" alignItems="center" className={styles.headerContainer}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" className={styles.title}>
            SERVICES WE OFFER
          </Typography>
          <Typography className={styles.description}>
            We offer below services.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={styles.buttonContainer}>
          {/* <Button variant="contained" color="inherit" className={styles.demoButton}>
            CONTACT FOR DEMO
          </Button> */}
        </Grid>
      </Grid>
      
      <Grid container spacing={5} className={styles.gridContainer}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={2} key={index} className={styles.serviceItem}>
            <div className={styles.circle}>
              <img src={service.img} alt={service.title} className={styles.serviceIcon} />
            </div>
            <Typography className={styles.serviceTitle}>
              {service.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
    </section>
  );
};

export default Services;

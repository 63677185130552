// src/components/Dashboard.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Typography,
  Button,
  ListItemIcon,
} from "@mui/material";
import { motion } from "framer-motion";
import Nav from "./Nav";
import styles from "./Dashbrd.module.css";

// Import Arrow Icons for Toggle Button
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// Import images for cards
import universalImg from "../../asset/dashb/universal.png";
import otImg from "../../asset/dashb/otImg.png";
import educationImg from "../../asset/dashb/educationImg.png";
import healthaiImg from "../../asset/dashb/healthImg.png";
import patientImg from "../../asset/dashb/patientImg.webp";
import pharmacyImg from "../../asset/dashb/pharmacyImg.png";
import industrialImg from "../../asset/dashb/industrialImg.webp";
import chemicalImg from "../../asset/dashb/chemicalImg.webp";
import classImg from "../../asset/dashb/classImg.png";
import cocImg from "../../asset/dashb/codeofconduct.png";
import taxImg from "../../asset/dashb/taxImg.png";

// Import Material-UI icons
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SchoolIcon from "@mui/icons-material/School";
import FactoryIcon from "@mui/icons-material/Factory";
import PublicIcon from '@mui/icons-material/Public';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";


const Dashbrd = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const welcomeMessage = location.state?.welcomeMessage;
  const username = location.state?.username;
  const [activeSection, setActiveSection] = useState("Universal");
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  useEffect(() => {
    if (welcomeMessage) {
      toast.success(welcomeMessage, {
        position: "top-center",
        autoClose: 2000,
      });
    }
  }, [welcomeMessage]);

  const handleDemoClick = () => {
    navigate("/demochat");
  };

  const getCards = () => {
    switch (activeSection) {
      case "Universal":
        return (
          <CardComponent
            title="Universal AI"
            description="Explore AI solutions tailored for universal applications."
            logo={universalImg}
            onDemoClick={handleDemoClick}
          />
        );

      case "Healthcare":
        return (
          <>
            <CardComponent
              title="Operation Theater"
              description="Manage surgical operations efficiently."
              logo={otImg}
              onDemoClick={handleDemoClick}
            />
            <CardComponent
              title="Patient Health"
              description="Track and manage patient health records."
              logo={patientImg}
              onDemoClick={handleDemoClick}
            />
            <CardComponent
              title="Clinical Module"
              description="Access and manage clinical data."
              logo={healthaiImg}
              onDemoClick={handleDemoClick}
            />
            <CardComponent
              title="Pharmacy"
              description="Manage pharmacy inventory and transactions."
              logo={pharmacyImg}
              onDemoClick={handleDemoClick}
            />
          </>
        );

      case "Education":
        return (
          <>
            <CardComponent
              title="Admission"
              description="Manage student admissions seamlessly."
              logo={educationImg}
              onDemoClick={handleDemoClick}
            />
            <CardComponent
              title="My Class"
              description="View and manage class schedules and resources."
              logo={classImg}
              onDemoClick={handleDemoClick}
            />
          </>
        );

      case "Industrial":
        return (
          <>
            <CardComponent
              title="My Industry"
              description="Manage industry operations and insights."
              logo={industrialImg}
              onDemoClick={handleDemoClick}
            />
            <CardComponent
              title="Chemicals"
              description="Oversee chemical inventory and safety protocols."
              logo={chemicalImg}
              onDemoClick={handleDemoClick}
            />
          </>
        );
        case "COC":
        return (
          <>
            <CardComponent
              title="Code of conduct"
              description="Maintain a respectful, collaborative, and inclusive workplace."
              logo={cocImg}
              onDemoClick={handleDemoClick}
            />
          </>
        );

        case "IncomeTax":
          return (
            <CardComponent
              title="Income Tax Department"
              description="Manage your income tax documents and filings."
              logo={taxImg}
              onDemoClick={handleDemoClick}
            />
          );

      default:
        return null;
    }
  };

  const CardComponent = ({ title, description, onDemoClick, logo }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <Card className={styles.card}>
        <CardContent>
          {logo && (
            <img src={logo} alt={`${title} logo`} className={styles.cardLogo} />
          )}
          <Typography
            variant="h5"
            component="div"
            style={{}}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ marginTop: "5px" }}
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            color="inherit"
            onClick={onDemoClick}
            style={{ marginTop: "20px", textTransform: "none" }}
          >
            Try Demo
          </Button>
        </CardContent>
      </Card>
    </motion.div>
  );

  // Toggle function to expand or collapse the sidebar
  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <div className={styles.dashboard}>
      <ToastContainer />
      <Nav username={username} />
      <div className={`${styles.sidebar} ${isSidebarExpanded ? styles.expanded : ""}`}>
        <List>
          <ListItem button onClick={() => setActiveSection("Universal")}>
            <ListItemIcon className={styles.sidebarIcon}>
              <PublicIcon fontSize="inherit" />
            </ListItemIcon>
            {isSidebarExpanded && <ListItemText primary="Universal" />}
          </ListItem>
          <ListItem button onClick={() => setActiveSection("Healthcare")}>
            <ListItemIcon className={styles.sidebarIcon}>
              <LocalHospitalIcon fontSize="inherit" />
            </ListItemIcon>
            {isSidebarExpanded && <ListItemText primary="Healthcare" />}
          </ListItem>
          <ListItem button onClick={() => setActiveSection("Education")}>
            <ListItemIcon className={styles.sidebarIcon}>
              <SchoolIcon fontSize="inherit" />
            </ListItemIcon>
            {isSidebarExpanded && <ListItemText primary="Education" />}
          </ListItem>
          <ListItem button onClick={() => setActiveSection("Industrial")}>
            <ListItemIcon className={styles.sidebarIcon}>
              <FactoryIcon fontSize="inherit" />
            </ListItemIcon>
            {isSidebarExpanded && <ListItemText primary="Industrial" />}
          </ListItem>
          <ListItem button onClick={() => setActiveSection("COC")}>
            <ListItemIcon className={styles.sidebarIcon}>
            <GavelIcon fontSize="inherit" />
            </ListItemIcon>
            {isSidebarExpanded && <ListItemText primary="Code of conduct" />}
          </ListItem>
          <ListItem button onClick={() => setActiveSection("IncomeTax")}>
            <ListItemIcon className={styles.sidebarIcon}>
              <AccountBalanceIcon fontSize="inherit" />
            </ListItemIcon>
            {isSidebarExpanded && <ListItemText primary="Income Tax Department" />}
          </ListItem>
        </List>
        <div className={styles.toggleButton} onClick={toggleSidebar}>
          {isSidebarExpanded ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
        </div>
      </div>

      <div className={styles.content}>{getCards()}</div>
    </div>
  );
};

export default Dashbrd;

import React from "react";
import { motion } from "framer-motion";

const TypingEffect = ({ text }) => {
  // Split the text into characters for individual animation
  const characters = Array.from(text);

  return (
    <span>
      {characters.map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0, x: -10 }} // Start off-screen
          animate={{ opacity: 1, x: 0 }} // Animate into view
          transition={{ duration: 0.2, delay: index * 0.1 }} // Staggered delay
        >
          {char}
        </motion.span>
      ))}
    </span>
  );
};

export default TypingEffect;

// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import styles from './Nav.module.css';

const Nav = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [username, setUsername] = useState('');

    // Retrieve username from local storage
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);

    // Opens the menu
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Closes the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Logout function
    const handleLogout = () => {
        // Clear any session storage or authentication data here
        localStorage.removeItem('user'); // Assuming you're storing session data in local storage

        handleClose();
        navigate('/login', { replace: true });
    };

    return (
        <AppBar position="fixed" style={{ backgroundColor: 'white', color: '#333' }}>
            <Toolbar>
                {/* Logo */}
                <Typography variant="h6" className={styles.logo}>
                    AI-MEBOT
                </Typography>

                <div className={styles.grow} />

                {/* Username and Profile Icon */}
                <Typography variant="body1" style={{ marginRight: '10px' }}>
                    {username|| 'Guest'}
                </Typography>
                <IconButton color="inherit" onClick={handleMenu}>
                    <AccountCircle />
                </IconButton>

                {/* Profile Menu */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            marginTop: '15px', // Space between Navbar and Menu
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
                            borderRadius: '8px', // Rounded corners
                            padding: '4px 0', // Extra padding inside the menu
                        },
                    }}
                >
                    <MenuItem onClick={handleLogout} style={{ padding: '5px 20px' }}>
                        Logout
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Nav;

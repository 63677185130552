// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./Admin";
// import FileUpload from "./component/demo/FileUpload";
import CreatePage from "./component/demo/CreatePage";
import ChatPage from "./component/demo/chatbot/ChatPage"
import Navbar from "./component/navbar/Navbar";
import HeroSection from "./component/hero/HeroSection";
import ServiceProcess from "./component/serviceprocess/ServiceProcess";
import Facilities from "./component/facilities/Facilities";
import ContextException from "./component/contextexception/ContextException";
import Services from "./component/services/Services";
import Testimonial from "./component/testimonials/Testimonial";
import ContactUs from "./component/contact/ContactUs";
import Footer from "./component/footer/Footer";
import Login from "./component/login/Login";
import Dashboard from './component/dashboard/Dashbrd'
import Demochats from './component/dashboard/chat/Demochats'
import Cocdashboard from './component/dashboard/chat/codeofconduct/Cocdashboard'
// import RegistrationForm from "./component/registration/RegistrationForm";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                {/* <RegistrationForm/> */}
                <HeroSection />
                <ServiceProcess/>
                <Facilities />
                <ContextException />
                <Services />
                <Testimonial />
                <ContactUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="/create-bot"
            element={
              <>
                <CreatePage/>
              </>
            }
          />
          
          <Route
            path="/mebot-chat"
            element={
              <>
                <ChatPage/>
              </>
            }
          />
          
          <Route
            path="/dashboard"
            element={
              <>
                <Dashboard/>
              </>
            }
          />
          <Route
            path="/demochat"
            element={
              <>
                <Demochats/>
              </>
            }
          />
          
          <Route
            path="/codeofconduct"
            element={
              <>
                <Cocdashboard/>
              </>
            }
          />

          <Route path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import styles from "./HeroSection.module.css";
import hero from "../../asset/hero.png";
import { Button } from "@mui/material";
import { motion } from 'framer-motion'; // Import motion

const HeroSection = () => {
  return (
    <section>
    <div id="home" className={styles.container}>
      <div className={styles.content}>
        {/* Left Section: Text */}
        <motion.div 
          className={styles.textSection}
          initial={{ x: '-100%', opacity: 0 }} // Start from the left
          animate={{ x: 0, opacity: 1 }} // Slide to original position
          transition={{ duration: 0.5 }} // Adjust duration as needed
        >
          <h1 className={styles.heading}>
            WELCOME TO <br /> AI MEBOT
          </h1>
          <p className={styles.paragraph}>
          Discover Limitless Possibilities: Meet MeBot, Your Smart AI Assistant!
          </p>
          <div className={styles.inputSection}>
            <div className={styles.inputContainer}>
              <input
                type="email"
                placeholder="Enter your email"
                className={styles.emailInput}
              />
              <Button
                style={{ textTransform: "none", fontWeight: "600" }}
                variant="contained"
                color="inherit"
                className={styles.requestButton}
              >
                Request Demo
              </Button>
            </div>
          </div>
        </motion.div>

        {/* Right Section: Image */}
        <motion.div 
          className={styles.imageSection}
          initial={{ x: '100%', opacity: 0 }} // Start from the right
          animate={{ x: 0, opacity: 1 }} // Slide to original position
          transition={{ duration: 0.5 }} // Adjust duration as needed
        >
          <img src={hero} alt="AI Mebot" className={styles.image} />
        </motion.div>
      </div>
    </div>
    </section>
  );
};

export default HeroSection;

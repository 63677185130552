import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUpload from './FileUpload'; // Import your FileUpload component
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { motion } from 'framer-motion'; // Import framer-motion for animations
import styles from './CreatePage.module.css';

const ChatbotPage = () => {
    const [showFileUpload, setShowFileUpload] = useState(false); // State to toggle between views
    const navigate = useNavigate(); // Initialize navigate

    const handleCreateClick = () => {
        setShowFileUpload(true); // Show the FileUpload page when button is clicked
    };

    // Function to handle back to home navigation
    const handleBackToHome = () => {
        navigate('/', { replace: true }); // Navigate to home route
    };

    return (
        <motion.div
            initial={{ scale: 0.5, opacity: 0 }} // Start smaller and transparent
            animate={{ scale: 1, opacity: 1 }} // Scale up and fade in
            exit={{ scale: 0.5, opacity: 0 }} // Scale down and fade out when exiting
            transition={{ duration: 0.5 }} // Duration of the animation
        >
            <Box className={styles.container}>
                <Box className={styles.innerContainer}>
                    {/* Left Panel */}
                    <Box className={styles.leftPanel}>
                        <Box className={styles.innerDashedBorder}>
                            <Typography variant="h6" className={styles.title}>Text Agents</Typography>
                            <hr className={styles.titleLine} />
                            <Box className={styles.emptyState}>
                                <Typography className={styles.emptyText}>No text agents available at this moment.</Typography>
                            </Box>
                        </Box>

                        {/* Create Chatbot Button */}
                        <Button
                            variant="contained"
                            color="inherit"
                            className={styles.createButton}
                            onClick={handleCreateClick}
                        >
                            Create chatbot
                        </Button>
                {/* Back to Home Button Below Container */}
                    <Button
                        variant="contained"
                        color="inherit"
                        className={styles.backButton}
                        onClick={handleBackToHome}
                    >
                        Back to Home
                    </Button>
                    </Box>

                    {/* Right Panel */}
                    <Box className={styles.rightPanel}>
                        {!showFileUpload ? (
                            <Box className={styles.createChatbot}>
                                {/* <AddCircleOutlineIcon className={styles.plusIcon} /> */}
                                <Typography className={styles.createTitle}>Create a chatbot to start</Typography>
                            </Box>
                        ) : (
                            <motion.div
                                initial={{ x: '-100%', opacity: 0 }} // Start off-screen to the left
                                animate={{ x: 0, opacity: 1 }} // Slide in from the left
                                exit={{ x: '-100%', opacity: 0 }} // Slide out to the left when unmounted
                                transition={{ duration: 0.5 }} // Duration of the animation
                            >
                                <Box className={styles.innerContainer}>
                                    <FileUpload onCancel={() => setShowFileUpload(false)} /> {/* Pass onCancel prop */}
                                </Box>
                            </motion.div>
                        )}
                    </Box>
                </Box>

            </Box>
        </motion.div>
    );
};

export default ChatbotPage;

import React, { useEffect, useRef } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { motion } from "framer-motion"; // Import motion from framer-motion
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styles from "./ServiceProcess.module.css";

const ServiceProcess = () => {
  const titleRef = useRef(null);
  const stepRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add animation class when the element is in view
          entry.target.classList.add(styles.animate);
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    }, options);

    // Observe title and step boxes
    if (titleRef.current) {
      observer.observe(titleRef.current);
    }
    stepRefs.current.forEach((step) => {
      if (step) {
        observer.observe(step);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section>
    <Box className={styles.container}>
      {/* Title with slide-in animation */}
      <motion.div
        ref={titleRef}
        initial={{ opacity: 0, x: 100 }} // Start off-screen to the right
        animate={{ opacity: 1, x: 0 }} // Slide in to its position
        transition={{ duration: 0.5 }} // Transition duration
      >
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          OUR SERVICE PROCESS THAT WE APPLY
        </Typography>
      </motion.div>

      {/* Button with zoom animation */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "20px",
          paddingRight: "40px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <motion.div
          initial={{ scale: 0 }} // Start scaled down to 0
          animate={{ scale: 1 }} // Scale to its original size
          transition={{ duration: 0.5 }} // Transition duration
        >
          {/* <Button
            sx={{
              mt: 2,
              backgroundColor: "#C9D1D8",
              color: "black",
              padding: "8px 25px",
              fontSize: "17px",
              borderRadius: "6px",
              textTransform: "uppercase",
              cursor: "pointer",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            Contact for Demo
          </Button> */}
        </motion.div>
      </Box>

      <Grid container spacing={4} justifyContent="center" className={styles.gridContainer}>
        <div className={styles.horizontalLine}></div>

        {/* Steps Sections */}
        {['AUDIT', 'INTEGRATION', 'ANALYSIS', 'RESULTS'].map((text, index) => {
          const icons = [
            <SearchIcon style={{ fontSize: 50, color: "#FFA500" }} />,
            <SettingsIcon style={{ fontSize: 50, color: "#0000FF" }} />,
            <BarChartIcon style={{ fontSize: 50, color: "#FF00FF" }} />,
            <CheckCircleIcon style={{ fontSize: 50, color: "#FF0000" }} />,
          ];

          return (
            <Grid item xs={12} sm={6} md={3} key={text}>
              <motion.div
                className={styles.stepBox}
                ref={(el) => (stepRefs.current[index] = el)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.3 }} // Delay based on index
              >
                <div className={`${styles.iconContainer} ${styles[`${text.toLowerCase()}Icon`]}`}>
                  {icons[index]}
                </div>
                <Typography variant="h5" className={styles[`${text.toLowerCase()}Text`]} gutterBottom>
                  {text}
                </Typography>
                <div className={styles[`${text.toLowerCase()}line`]}></div>
                <Typography variant="body1" sx={{ paddingLeft: "50px", paddingRight: "50px" }}>
                Innovative RAG framework blends data retrieval with AI, ensuring accurate, context-rich responses for real-time, dynamic interactions.                </Typography>
              </motion.div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
    </section>
  );
};

export default ServiceProcess;

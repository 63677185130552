// import React, { useState } from "react";
// import { Button, List, ListItem, ListItemText } from "@mui/material";
// import Coc from "./Coc"; // Chat page
// import styles from "./Dashboard.module.css";

// // Import logo paths
// import OutlookLogo from "../../../../asset/dashb/outlook_icon.png";
// import GmailLogo from "../../../../asset/dashb/gmail_icon.png"
// import CalendarLogo from "../../../../asset/dashb/gcalendar_icon.png";

// const Dashboard = () => {
//   const [selectedBot, setSelectedBot] = useState(null);
//   const [showChatPage, setShowChatPage] = useState(false);
//   const bots = ["Code of Conduct"]; 

//   const handleSelectBot = (bot) => {
//     setSelectedBot(bot);
//     setShowChatPage(false); 
//   };

//   const handleCreateBot = () => {
//     if (selectedBot) {
//       setShowChatPage(true);
//     } else {
//       alert("Please select a bot first.");
//     }
//   };

//   return (
//     <div className={styles.dashboard}>
//       {/* Left Sidebar */}
//       <div className={styles.sidebar}>
//         <div className={styles.sidebarHeader}>
//           <span className={styles.botName}>Mebot</span>
//         </div>

//         {/* List of Bots */}
//         <List className={styles.botList}>
//           {bots.map((bot, index) => (
//             <ListItem
//               button
//               key={index}
//               selected={selectedBot === bot} // Highlight selected bot
//               onClick={() => handleSelectBot(bot)}
//               className={`${styles.botListItem} ${
//                 selectedBot === bot ? styles.selectedBot : ""
//               }`}
//             >
//               <ListItemText primary={bot} />
//             </ListItem>
//           ))}
//         </List>

//         {/* Create Bot Button */}
//         <Button
//           variant="contained"
//           color="inherit"
//           className={styles.createBotButton}
//           onClick={handleCreateBot}
//           disabled={!selectedBot} // Disable button until a bot is selected
//         >
//           Create Bot
//         </Button>
//       </div>

//       {/* Main Content */}
//       <div className={styles.mainContent}>
//         {showChatPage ? (
//           <div className={styles.chatPageWrapper}>
//             {/* Top Bar with Icons */}
//             <div className={styles.topBar}>
//               <img
//                 src={OutlookLogo}
//                 alt="Outlook"
//                 className={styles.icon}
//                 title="Outlook"
//               />
//               {/* <img
//                 src={GmailLogo}
//                 alt="Gmail"
//                 className={styles.icon}
//                 title="Gmail"
//               /> */}
//               <img
//                 src={CalendarLogo}
//                 alt="Calendar"
//                 className={styles.icon}
//                 title="Calendar"
//               />
//             </div>

//             {/* Chat Page */}
//             <Coc botName={selectedBot} />
//           </div>
//         ) : (
//           <div className={styles.placeholder}>
//             <h2>Welcome to the Dashboard</h2>
//             <p>Select a bot from the list and click "Create Bot" to get started.</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

// import React, { useState } from "react";
// import { Button, List, ListItem, ListItemText } from "@mui/material";
// import Coc from "./Coc"; // Chat page
// import styles from "./Cocdashboard.module.css";

// // Import logo paths
// import OutlookLogo from "../../../../asset/dashb/outlook_icon.png";
// import CalendarLogo from "../../../../asset/dashb/gcalendar_icon.png";

// const Dashboard = () => {
//   const [selectedBot, setSelectedBot] = useState(null);
//   const [showChatPage, setShowChatPage] = useState(false);
//   const [chatContent, setChatContent] = useState(""); // State for chat content
//   const bots = ["Code of Conduct"];

//   const handleSelectBot = (bot) => {
//     setSelectedBot(bot);
//     setShowChatPage(false);
//   };

//   const handleCreateBot = () => {
//     if (selectedBot) {
//       setShowChatPage(true);
//     } else {
//       alert("Please select a bot first.");
//     }
//   };

//   // Function to handle calendar icon click
//   const handleCalendarClick = () => {
//     window.open("https://calendar.google.com", "_blank");
//   };

//   // Function to handle Outlook click
//   const handleOutlookClick = () => {
//     if (!chatContent) {
//       alert("No chat content to send.");
//       return;
//     }
//     const mailtoLink = `mailto:?subject=${encodeURIComponent(
//       "Code of Conduct Response"
//     )}&body=${encodeURIComponent(chatContent)}`;
//     window.open(mailtoLink, "_blank"); // Open the mailto link in a new tab
//   };

//   return (
//     <div className={styles.dashboard}>
//       {/* Left Sidebar */}
//       <div className={styles.sidebar}>
//         <div className={styles.sidebarHeader}>
//           <span className={styles.botName}>Mebot</span>
//         </div>

//         {/* List of Bots */}
//         <List className={styles.botList}>
//           {bots.map((bot, index) => (
//             <ListItem
//               button
//               key={index}
//               selected={selectedBot === bot}
//               onClick={() => handleSelectBot(bot)}
//               className={`${styles.botListItem} ${
//                 selectedBot === bot ? styles.selectedBot : ""
//               }`}
//             >
//               <ListItemText primary={bot} />
//             </ListItem>
//           ))}
//         </List>

//         {/* Create Bot Button */}
//         <Button
//           variant="contained"
//           color="inherit"
//           className={styles.createBotButton}
//           onClick={handleCreateBot}
//           disabled={!selectedBot}
//         >
//           Create Bot
//         </Button>
//       </div>

//       {/* Main Content */}
//       <div className={styles.mainContent}>
//         {showChatPage ? (
//           <div className={styles.chatPageWrapper}>
//             {/* Top Bar with Icons */}
//             <div className={styles.topBar}>
//               <img
//                 src={OutlookLogo}
//                 alt="Outlook"
//                 className={styles.icon}
//                 title="Outlook"
//                 onClick={handleOutlookClick}
//                 style={{ cursor: "pointer" }}
//               />
//               <img
//                 src={CalendarLogo}
//                 alt="Calendar"
//                 className={styles.icon}
//                 title="Calendar"
//                 onClick={handleCalendarClick}
//                 style={{ cursor: "pointer" }}
//               />
//             </div>

//             {/* Chat Page */}
//             <Coc
//               botName={selectedBot}
//               setChatContent={setChatContent} // Pass setChatContent to Coc
//             />
//           </div>
//         ) : (
//           <div className={styles.placeholder}>
//             <h2>Welcome to the Dashboard</h2>
//             <p>Select a bot from the list and click "Create Bot" to get started.</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useState } from "react";
import { Button, List, ListItem, ListItemText } from "@mui/material";
import Coc from "./Coc"; // Chat page
import styles from "./Cocdashboard.module.css";

// Import logo paths
import OutlookLogo from "../../../../asset/dashb/outlook_icon.png";
import CalendarLogo from "../../../../asset/dashb/gcalendar_icon.png";

const Dashboard = () => {
  const [selectedBot, setSelectedBot] = useState(null);
  const [showChatPage, setShowChatPage] = useState(false);
  const [chatContent, setChatContent] = useState(""); // State for chat content
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [recipient, setRecipient] = useState(""); // State for recipient
  const [subject, setSubject] = useState("Code of Conduct Response"); // Default subject

  const bots = ["Code of Conduct"];

  const handleSelectBot = (bot) => {
    setSelectedBot(bot);
    setShowChatPage(false);
  };

  const handleCreateBot = () => {
    if (selectedBot) {
      setShowChatPage(true);
    } else {
      alert("Please select a bot first.");
    }
  };

  // Function to handle Outlook icon click
  const handleOutlookClick = () => {
    if (!chatContent) {
      alert("No chat content to send.");
      return;
    }
    setShowPopup(true); // Show the popup
  };

  // Function to handle close popup
  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup
  };

  // Function to handle Send button click
  const handleSendEmail = () => {
    if (!recipient.trim()) {
      alert("Please enter a recipient email.");
      return;
    }
    const mailtoLink = `mailto:${encodeURIComponent(recipient)}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(chatContent)}`;
    window.open(mailtoLink, "_blank"); 
    setShowPopup(false); 
  };

  const handleCalendarClick = () => {
    window.open("https://calendar.google.com", "_blank");
  };

  return (
    <div className={styles.dashboard}>
      {/* Left Sidebar */}
      <div className={styles.sidebar}>
        <div className={styles.sidebarHeader}>
          <span className={styles.botName}>Mebot</span>
        </div>

        {/* List of Bots */}
        <List className={styles.botList}>
          {bots.map((bot, index) => (
            <ListItem
              button
              key={index}
              selected={selectedBot === bot}
              onClick={() => handleSelectBot(bot)}
              className={`${styles.botListItem} ${
                selectedBot === bot ? styles.selectedBot : ""
              }`}
            >
              <ListItemText primary={bot} />
            </ListItem>
          ))}
        </List>

        {/* Create Bot Button */}
        <Button
          variant="contained"
          color="inherit"
          className={styles.createBotButton}
          onClick={handleCreateBot}
          disabled={!selectedBot}
        >
          Create Bot
        </Button>
      </div>

      {/* Main Content */}
      <div className={styles.mainContent}>
        {showChatPage ? (
          <div className={styles.chatPageWrapper}>
            {/* Top Bar with Icons */}
            <div className={styles.topBar}>
              <img
                src={OutlookLogo}
                alt="Outlook"
                className={styles.icon}
                title="Outlook"
                onClick={handleOutlookClick}
                style={{ cursor: "pointer" }}
              />
              <img
                src={CalendarLogo}
                alt="Calendar"
                className={styles.icon}
                title="Calendar"
                onClick={handleCalendarClick}
                style={{ cursor: "pointer" }}
              />
            </div>

            {/* Chat Page */}
            <Coc
              botName={selectedBot}
              setChatContent={setChatContent} // Pass setChatContent to Coc
            />
          </div>
        ) : (
          <div className={styles.placeholder}>
            <h2>Compliance AI Officer</h2>
            <p>Select a bot from the list and click "Create Bot" to get started.</p>
          </div>
        )}
      </div>

      {/* Popup for Outlook Email */}
      {showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popup}>
            <button className={styles.closeButton} onClick={handleClosePopup}>
              &times;
            </button>
            <h3>Response Mail</h3>
            <div className={styles.formGroup}>
              <label>Recipient:</label>
              <input
                type="email"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                placeholder="Enter recipient email"
              />
            </div>
            <div className={styles.formGroup}>
              <label>Subject:</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Message:</label>
              <textarea
                value={chatContent}
                readOnly
                className={styles.readOnlyMessage}
              ></textarea>
            </div>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleSendEmail}
            >
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Link, Typography, Box, Container, Grid, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; 
import styles from './Login.module.css';
import LoginImg from "../../asset/loginimage.png";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordTyped, setPasswordTyped] = useState(false);

  // Hardcoded user credentials
  const users = {
    Admin: { username: 'admin', password: 'pssadmin@2024' },
    Support: { username: 'support', password: 'psssupport@2024' },
    Demo: { username: 'demo', password: 'pssdemo@2024' },
    Aniket: { username: 'aniket', password: 'ani@2024' },
    Jeren: { username: 'jeren', password: 'pss@2024' },
    Quayum: { username: 'quayum', password: 'qua@2024' }
  };

  const handleLogin = () => {
    // Check for empty username or password
    if (!username || !password) {
      toast.error("Username and password are required.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId: "errorEmptyFields" // Unique ID for this toast
      });
      return; 
    }

    const normalizedUsername = username.toLowerCase();
    const user = Object.values(users).find(
      (user) => user.username.toLowerCase() === normalizedUsername && user.password === password
    );

    if (user) {
      setTimeout(() => {
        // Pass only state and avoid toast here
        localStorage.setItem('username', user.username);
        navigate('/dashboard', { state: { welcomeMessage: `Welcome, ${user.username}!`, username: user.username } });
      }, 500); 
    } else {
      toast.error("Invalid username or password", {
        position: "top-right", 
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        toastId: "error1" 
      });
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordTyped(e.target.value.length > 0);
  };

  return (
    <motion.div 
      initial={{ y: '-100%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Container component="main" maxWidth="sm" className={styles.container}>
        <ToastContainer 
          position="top-right"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          pauseOnHover
        /> {/* Toast container to display notifications */}
        <Box className={styles.box} boxShadow={5} borderRadius={5}>
          <Grid container direction="column" alignItems="center">
            <img src={LoginImg} alt="Login Illustration" className={styles.image} />
            <Box component="form" noValidate autoComplete="off" className={styles.form}>
              <TextField
                variant="standard"
                margin="normal"
                fullWidth
                id="email"
                label="Username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className={styles.textField}
                aria-label="username"
              />
              <TextField
                variant="standard"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                className={styles.textField}
                aria-label="password"
                InputProps={{
                  endAdornment: (
                    passwordTyped && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  ),
                }}
              />
              <Grid container justifyContent="end" className={styles.links}>
                <Grid item>
                  <Link href="#" variant="body2" color="#FF0004">
                    Forgot Password
                  </Link>
                </Grid>
              </Grid>
              <Button
                color="inherit"
                variant="contained"
                className={styles.button}
                onClick={handleLogin}
              >
                Sign in
              </Button>
              <Grid container justifyContent="center" className={styles.register}>
                <Typography variant="body2">Need new account?</Typography>
                <Link href="#" variant="body2" color="primary" className={styles.registerLink}>
                  Register now
                </Link>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Button
          variant="contained"
          color="inherit"
          onClick={handleBackToHome}
          className={styles.backButton}
        >
          Back to Home
        </Button>
      </Container>
    </motion.div>
  );
};

export default Login;

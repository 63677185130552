// import React, { useState, useEffect, useRef } from 'react';
// import { Paper, IconButton, Button, TextareaAutosize } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import SendIcon from '@mui/icons-material/Send';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { motion } from 'framer-motion';
// import bot from '../../../asset/bot.png';
// import axios from 'axios';
// import DarkModeIcon from '@mui/icons-material/DarkMode';
// import LightModeIcon from '@mui/icons-material/LightMode';
// import styles from './ChatPage.module.css';

// const ChatPage = () => {
//   const [messages, setMessages] = useState([
//     { sender: 'bot', text: "Hello and welcome!😊 I'm here to assist you with your queries. How can I help you today?" }
//   ]);
//   const [query, setQuery] = useState('');
//   const [isTyping, setIsTyping] = useState(false);
//   const [isDarkMode, setIsDarkMode] = useState(false);
//   const messagesEndRef = useRef(null);
//   const navigate = useNavigate();

//   const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!query.trim()) {
//       console.error('Input value is empty');
//       return;
//     }

//     const userMessage = { sender: 'user', text: query };
//     setMessages((prevMessages) => [...prevMessages, userMessage]);
//     setQuery('');

//     setMessages((prevMessages) => [
//       ...prevMessages,
//       { sender: 'bot', text: 'typing' }
//     ]);

//     const responseText = await fetchDataFromAPI(query);
//     await simulateTypingEffect(responseText);
//   };

//   const fetchDataFromAPI = async (input) => {
//     const structuredInput = `answer concisely and without explanation in a single sentence with no additional information,"${input}"`;

//     const data = {
//       input: structuredInput,
//       config: {},
//       kwargs: {}
//     };

//     try {
//       const response = await axios.post('api/rag_chain/invoke', data, {
//         headers: { 'Content-Type': 'application/json' }
//       });
//       const output = response.data.output;
//       const answerKey = '\nAnswer:';
//       const answerIndex = output.indexOf(answerKey);

//       if (answerIndex !== -1) {
//         const result = output.substring(answerIndex + answerKey.length).trim();
//         return result.length > 0 ? result : 'No valid answer found in response';
//       }

//       return 'No valid answer found in response';
//     } catch (error) {
//       console.error('Error fetching data from API:', error);
//       return 'Error fetching data from API';
//     }
//   };

//   const simulateTypingEffect = async (text) => {
//     setIsTyping(true);

//     setMessages((prevMessages) => [
//       ...prevMessages.slice(0, -1),
//       { sender: 'bot', text: '' }
//     ]);

//     let index = 0;
//     let typingMessage = '';

//     const typeCharacter = () => {
//       typingMessage += text.charAt(index);
//       index++;

//       setMessages((prevMessages) => [
//         ...prevMessages.slice(0, -1),
//         { sender: 'bot', text: typingMessage }
//       ]);

//       if (index < text.length) {
//         const delay = 5;
//         setTimeout(typeCharacter, delay);
//       } else {
//         setIsTyping(false);
//         setMessages((prevMessages) => [
//           ...prevMessages.slice(0, -1),
//           { sender: 'bot', text }
//         ]);
//       }
//     };

//     typeCharacter();
//   };

//   const handleDeleteChat = () => {
//     setMessages([]);
//     setTimeout(() => {
//       setMessages([
//         { sender: 'bot', text: "Hello and welcome!😊 I'm here to assist you with your queries. How can I help you today?" }
//       ]);
//     }, 300);
//   };

//   useEffect(() => {
//     if (messagesEndRef.current) {
//       messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [messages]);

//   const handleKeyPress = (e) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSubmit(e);
//     }
//   };

//   const handleBackToHome = () => navigate('/', { replace: true });

//   return (
//     <div className={`${styles.outerContainer} ${isDarkMode ? styles.dark : ''}`}>
//       <Paper className={`${styles.chatContainer} ${isDarkMode ? styles.dark : ''}`}>
//         <div className={styles.header}>
//           <div className={styles.botInfo}>
//             <img src={bot} alt="Bot" className={styles.botIcon} />
//             <div className={styles.botDetails}>
//               <span className={styles.botName}>MEBOT</span>
//               <span className={styles.onlineStatus}>
//                 <span>online</span> <span className={styles.statusDot}></span>
//               </span>
//             </div>
//           </div>
//           <div className={styles.icons}>
//             <IconButton onClick={toggleDarkMode} className={styles.themeToggleIcon}>
//               {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
//             </IconButton>
//             <IconButton onClick={handleDeleteChat} className={styles.deleteIcon}>
//               <DeleteIcon />
//             </IconButton>
//           </div>
//         </div>

//         <div className={styles.chatWindow}>
//           {messages.map((message, index) => (
//             <motion.div
//               key={index}
//               className={`${styles.messageContainer} ${
//                 message.sender === 'bot' ? styles.leftMessage : styles.rightMessage
//               }`}
//               initial={{ opacity: 0, x: message.sender === 'bot' ? -30 : 30 }}
//               animate={{ opacity: 1, x: 0 }}
//               exit={{ opacity: 0, x: message.sender === 'bot' ? -30 : 30 }}
//               transition={{ type: 'spring', stiffness: 80, damping: 15 }}
//             >
//               {message.sender === 'bot' && (
//                 <img src={bot} alt="Bot" className={styles.avatar} />
//               )}
//               <div
//                 className={`${styles.message} ${
//                   message.sender === 'bot' ? styles.botMessage : styles.userMessage
//                 }`}
//               >
//                 {message.text === 'typing' ? (
//                   <div className={styles.typingDots}>
//                     <span className={styles.dot}></span>
//                     <span className={styles.dot}></span>
//                     <span className={styles.dot}></span>
//                   </div>
//                 ) : (
//                   message.text
//                 )}
//               </div>
//             </motion.div>
//           ))}
//           <div ref={messagesEndRef}></div>
//         </div>

//         <form onSubmit={handleSubmit} className={styles.inputSection}>
//           <div className={styles.inputWrapper}>
//             <TextareaAutosize
//               style={{ resize: 'none' }}
//               minRows={1}
//               maxRows={10}
//               placeholder="Write query here..."
//               value={query}
//               onChange={(e) => setQuery(e.target.value)}
//               onKeyDown={handleKeyPress}
//               className={`${styles.customInput} ${isDarkMode ? styles.darkInput : ''}`}
//             />
//           </div>
//           <IconButton type="submit" className={styles.sendButton}>
//             <SendIcon />
//           </IconButton>
//         </form>
//         <div className={styles.backButtonContainer}>
//           <Button
//             variant="contained"
//             color="inherit"
//             onClick={handleBackToHome}
//             className={styles.backButton}
//           >
//             Back to Home
//           </Button>
//         </div>
//       </Paper>
//     </div>
//   );
// };

// export default ChatPage;

import React, { useState, useEffect, useRef } from 'react';
import { Paper, IconButton, Button, TextareaAutosize } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { motion } from 'framer-motion';
import pssBot from '../../../asset/pssBot.png';
import axios from 'axios';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import styles from './ChatPage.module.css';

const TypingDots = () => {
  return (
    <div className={styles.typingDots}>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </div>
  );
};

const ChatPage = () => {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: "Hello and welcome!😊 I'm here to assist you with your queries. How can I help you today?", isTypingComplete: true }
  ]);
  const [query, setQuery] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // New state for processing status
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  const handleSubmit = async (e, text) => {
    if (e) e.preventDefault();

    const messageText = text || query;

    if (!messageText.trim()) {
      console.error('Input value is empty');
      return;
    }

    setIsProcessing(true); // Start processing, disable send button

    const userMessage = { sender: 'user', text: messageText };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setQuery('');

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: 'bot', text: 'typing', isTypingComplete: false }
    ]);

    const responseText = await fetchDataFromAPI(messageText);
    await simulateTypingEffect(responseText);
    setIsProcessing(false); // End processing, re-enable send button
  };

  const fetchDataFromAPI = async (input) => {
    const structuredInput = `answer concisely and without explanation in a single sentence with no additional information,"${input}"`;

    const data = {
      input: structuredInput,
      config: {},
      kwargs: {}
    };

    try {
      const response = await axios.post('api/rag_chain/invoke', data, {
        headers: { 'Content-Type': 'application/json' }
      });
      const output = response.data.output;
      const answerKey = '\n\nAnswer:';
      const answerIndex = output.indexOf(answerKey);

      if (answerIndex !== -1) {
        const result = output.substring(answerIndex + answerKey.length).trim();
        return result.length > 0 ? result : 'No valid answer found in response';
      }

      return 'No valid answer found in response';
    } catch (error) {
      console.error('Error fetching data from API:', error);
      return 'Error fetching data from API';
    }
  };

  const simulateTypingEffect = async (text) => {
    setIsTyping(true);

    let formattedText = text;

    setMessages((prevMessages) => [
      ...prevMessages.slice(0, -1),
      { sender: 'bot', text: 'typing', isTypingComplete: false }
    ]);

    await new Promise((resolve) => setTimeout(resolve, 1500));

    setIsTyping(false);
    setMessages((prevMessages) => [
      ...prevMessages.slice(0, -1),
      { sender: 'bot', text: formattedText, isTypingComplete: true }
    ]);
  };

  const handleDeleteChat = () => {
    setMessages([]);
    setTimeout(() => {
      setMessages([
        { sender: 'bot', text: "Hello and welcome!😊 I'm here to assist you with your queries. How can I help you today?", isTypingComplete: true }
      ]);
    }, 300);
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isProcessing) {
        handleSubmit();
      }
    }
  };

  const handleBackToHome = () => navigate('/', { replace: true });

  const startListening = () => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Speech Recognition API not supported in this browser.');
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;

    recognition.start();

    recognition.onstart = () => setIsListening(true);
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setQuery(transcript);

      if (event.results[0].isFinal) {
        setIsListening(false);
        handleSubmit(null, transcript);
      }
    };
    recognition.onend = () => setIsListening(false);
    recognition.onerror = (event) => {
      console.error('Speech recognition error', event.error);
      setIsListening(false);
    };
  };

  const readAloud = (text) => {
    const speechSynthesis = window.speechSynthesis;
  
    // Check if speech synthesis is supported
    if (!speechSynthesis) {
      console.error("Text-to-Speech not supported in this browser.");
      return;
    }
  
    // Cancel any ongoing speech
    if (speechSynthesis.speaking || speechSynthesis.pending) {
      speechSynthesis.cancel();
    }
  
     // Strip out HTML tags using a regex
  const plainText = text.replace(/<[^>]*>/g, '').replace(/[<*_>]/g, '');

  // Create a new SpeechSynthesisUtterance instance with the sanitized text
  const utterance = new SpeechSynthesisUtterance(plainText);
  
    // Set language and rate
    utterance.lang = 'hi-IN';
    utterance.rate = 1;
    utterance.pitch = 1;
  
    // Function to set the selected voice
    const setVoice = () => {
      const voices = speechSynthesis.getVoices();
      const hindiVoice = voices.find(voice => voice.lang === 'hi-IN') || voices[0];
      utterance.voice = hindiVoice;
  
      // Start speaking once the voice is set
      speechSynthesis.speak(utterance);
    };
  
    // Check if voices are already loaded
    if (speechSynthesis.getVoices().length) {
      setVoice();
    } else {
      // Add an event listener to load voices if they're not ready immediately
      speechSynthesis.onvoiceschanged = () => {
        setVoice();
        speechSynthesis.onvoiceschanged = null; // Remove the listener after the voices load
      };
    }
  };

 return (
    <div className={`${styles.outerContainer} ${isDarkMode ? styles.dark : ''}`}>
      <Paper className={`${styles.chatContainer} ${isDarkMode ? styles.dark : ''}`}>
        <div className={styles.header}>
          <div className={styles.botInfo}>
            <img src={pssBot} alt="Bot" className={styles.botIcon} />
            <div className={styles.botDetails}>
              <span className={styles.botName}>Mebot</span>
              <span className={styles.onlineStatus}>
                <span>online</span> <span className={styles.statusDot}></span>
              </span>
            </div>
          </div>
          <div className={styles.icons}>
            <IconButton onClick={toggleDarkMode} className={styles.themeToggleIcon}>
              {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
            <IconButton onClick={handleDeleteChat} className={styles.deleteIcon}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>

        <div className={styles.chatWindow}>
          {messages.map((message, index) => (
            <motion.div
              key={index}
              className={`${styles.messageContainer} ${message.sender === 'bot' ? styles.leftMessage : styles.rightMessage}`}
              initial={{ opacity: 0, x: message.sender === 'bot' ? -30 : 30 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: message.sender === 'bot' ? -30 : 30 }}
              transition={{ type: 'spring', stiffness: 80, damping: 15 }}
            >
              {message.sender === 'bot' && (
                <img src={pssBot} alt="Bot" className={styles.avatar} />
              )}
              <div
                className={`${styles.message} ${message.sender === 'bot' ? styles.botMessage : styles.userMessage}`}
              >
                {message.sender === 'bot' && !message.isTypingComplete ? (
                  <TypingDots />
                ) : (
                  <span
                    dangerouslySetInnerHTML={
                      message.sender === 'bot' && message.isTypingComplete
                        ? { __html: message.text }
                        : undefined
                    }
                  />
                )}
                {message.sender !== 'bot' && message.text}
                {/* Place the sound icon inside the chat window, at the end of the last bot message */}
              </div>
              {message.sender === 'bot' && message.isTypingComplete && (
                <IconButton
                  onClick={() => readAloud(message.text)}
                  className={styles.soundButton}
                >
                  <VolumeUpIcon />
                </IconButton>
              )}
            </motion.div>
          ))}
          <div ref={messagesEndRef}></div>
        </div>

        <form onSubmit={(e) => handleSubmit(e)} className={styles.inputSection}>
          <div className={`${styles.inputWrapper} ${isDarkMode ? styles.darkInputWrapper : ''}`}>
            <TextareaAutosize
              style={{ resize: 'none' }}
              minRows={1}
              maxRows={10}
              placeholder="Message Mebot"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={handleKeyPress}
              className={styles.customInput}
            />

            <IconButton
              onClick={startListening}
              className={`${styles.micButton} ${isListening ? styles.listeningPulse : ''}`}
            >
              {isListening ? (
                <div className={styles.listeningWave}>
                  <div className={styles.waveBar} style={{ '--i': 1 }}></div>
                  <div className={styles.waveBar} style={{ '--i': 2 }}></div>
                  <div className={styles.waveBar} style={{ '--i': 3 }}></div>
                </div>
              ) : (
                <MicIcon color={isListening ? 'primary' : 'inherit'} />
              )}
            </IconButton>

            <IconButton
              type="submit"
              className={`${styles.sendButton} ${isProcessing ? styles.disabledButton : ''}`}
              disabled={isProcessing || !query.trim()}
            >
              {isProcessing ? (
                <div className={styles.spinner}></div>  // Show spinner when processing
              ) : (
                <SendIcon />
              )}
            </IconButton>
          </div>
        </form>

        <div className={styles.backButtonContainer}>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleBackToHome}
            className={styles.backButton}
          >
            Back to Dashboard
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default ChatPage;
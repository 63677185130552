import React from "react";
import { Grid, Typography, Link, Button } from "@mui/material";
import {
  Twitter,
  Facebook,
  Instagram,
  LinkedIn,
  Room,
  Phone,
  Email,
} from "@mui/icons-material";
import styles from "./Footer.module.css"; // Import the CSS module

const Footer = () => {
  return (
    
    <div className={styles.footerContainer}>
      <Grid container spacing={4} className={styles.footer}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" className={styles.logo}>
            AI-MEBOT
          </Typography>
          <Typography variant="body2" className={styles.description}>
            
          </Typography>
          <div className={styles.socialIcons}>
            <Twitter className={styles.icon} />
            <Facebook className={styles.icon} />
            <Instagram className={styles.icon} />
            <LinkedIn className={styles.icon} />
          </div>
        </Grid>

        {/* Useful Links */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" className={styles.sectionTitle}>
            Useful Links
          </Typography>
          <ul className={styles.linkList}>
            <li>
              <Link href="#" underline="hover">
                Home
              </Link>
            </li>
            <li>
              <Link href="#" underline="hover">
                Service
              </Link>
            </li>
            <li>
              <Link href="#" underline="hover">
                Testimonials
              </Link>
            </li>
            <li>
              <Link href="#" underline="hover">
                Contact Us
              </Link>
            </li>
            <li>
              <Link href="#" underline="hover">
                Our Locations
              </Link>
            </li>
          </ul>
        </Grid>

        {/* Our Services */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" className={styles.sectionTitle}>
            Our Services
          </Typography>
          <ul className={styles.linkList}>
            <li>Web AI MEBOT Tool</li>
            <li>AI MEBOT Remediation</li>
            <li>AI MEBOT Monitoring</li>
            <li>Our philosophy and process</li>
            <li>Web AI MEBOT Consulting</li>
            <li>Multimedia MEBOT</li>
          </ul>
        </Grid>

        {/* Contact Us */}
        <Grid item xs={12} md={2.6}>
          <Typography
            
            variant="h6"
            className={styles.sectionTitle}
          >
            Contact Us
          </Typography>
          <ul className={styles.contactList}>
            {/* <li>
              <Room /> Sector 12 Spin Road, Moshi, Pune
            </li><br></br> */}
            <li>
              <Phone /> +91 70304 03299
            </li><br></br>
            <li>
              <Email /> info@mebot.live
            </li>
          </ul>
          {/* <Button
            sx={{ textTransform: "none" }}
            style={{ fontWeight: "600" }}
            variant="contained"
            color="inherit"
            className={styles.directionsButton}
          >
            Get directions
          </Button> */}
        </Grid>
      </Grid>

      <div className={styles.bottomSection}>
        <Typography variant="body2" className={styles.copyright}>
          Copyright © 2024 AI MEBOT
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
